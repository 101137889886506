import { Context } from './Context'
import { get, isEmpty } from 'lodash'

export type PathWalkerIteratee = (config: PathInfo) => void

export interface PathInfo {
	debug?: boolean
	name?: string
	namespace?: string
	index?: number
}

/**
 * Given an Array of PathInfo (field configuration) objects and a user data Context walk the configuration over each field value in the Context.
 * Warning: pathWalker modifies the PathInfo field configurtion objects for performance.
 * @param config
 * @param iteratee
 * @param context
 */
export function pathWalker(
	config: PathInfo[],
	iteratee: PathWalkerIteratee,
	context: Context
): void {
	if (process.env.NODE_ENV === 'development') {
		console.time('pathWalker')
	}
	config.forEach((pathInfo: PathInfo) => {
		const { name, namespace, index, ...fieldConfig } = pathInfo
		const path: string[] = pathToArray(getPath({ namespace, index }))
		function walk(context: any, path: string[], pathIndex: number, state: any) {
			const pathPart = path[pathIndex]
			const ns = state.ns
			pathIndex++
			if (pathPart) {
				const value = get(context, pathPart)
				if (Array.isArray(value)) {
					value.forEach((next, index) => {
						if (pathIndex < path.length) {
							state.ns = ns.concat(`${pathPart}[${index}]`)
						} else {
							state.ns = ns.concat(pathPart)
							state.index = index
						}
						walk(next, path, pathIndex, state)
					})
				} else {
					state.ns = ns.concat(pathPart)
					walk(value, path, pathIndex, state)
				}
				return
			}
			const isItemGroupItem = Number.isInteger(state.index)
			// for performance only shallow copy when needed
			const updatedPathInfo: PathInfo = isItemGroupItem ? { ...fieldConfig } : fieldConfig
			if (name) {
				updatedPathInfo.name = name
			}
			if (state.ns.length > 0) {
				updatedPathInfo.namespace = state.ns.join('.')
			}
			if (Number.isInteger(state.index)) {
				updatedPathInfo.index = state.index
			}
			iteratee(updatedPathInfo)
		}
		walk(context, path, 0, { ns: [] })
	})
	if (process.env.NODE_ENV === 'development') {
		console.timeEnd('pathWalker')
	}
}

export function getPath(node: PathInfo | string | undefined, throws: boolean = false): string {
	if (node == null || isEmpty(node)) {
		return ''
	}
	if (typeof node === 'string') {
		return node
	}
	const path: string[] = []
	const hasName = typeof node.name === 'string' && node.name

	const namespace = getNamespace(node, throws)

	if (namespace) {
		path.push(namespace)
	}

	if (hasName) {
		const name = String(node.name).trim()
		const hasBrackets = /^\[[^\]]+\]/.test(name)
		if (hasBrackets) {
			path.push(name)
		} else {
			if (path.length > 0) {
				path.push(`.${name}`)
			} else {
				path.push(name)
			}
		}
	}

	return path.join('')
}

export function getNamespace(node: PathInfo | string | undefined, thows: boolean = false): string {
	if (node == null || isEmpty(node)) {
		return ''
	}
	if (typeof node === 'string') {
		return node
	}
	const path: string[] = []
	const hasNamespace = typeof node.namespace === 'string' && node.namespace
	const index = Number(node.index)
	const hasIndex = Number.isInteger(index)

	if (hasNamespace) {
		path.push(String(node.namespace).trim())
	}

	if (hasIndex) {
		path.push(`[${index}]`)
	}

	return path.join('')
}

export function getParentPath(path: string | PathInfo | undefined): string {
	const parts = pathToArray(getPath(path))
	if (parts.length === 1) {
		return ''
	}
	// collect .name[#] parts and ignore .name parts
	const ancestors = parts.filter((part) => /\[[0-9]+\]/.test(part))
	// remove the last .name[#] part
	ancestors.pop()
	// return path to parent object
	return ancestors.join('.')
}

export function pathToArray(path: string | PathInfo): string[] {
	if (path == null) {
		return []
	}
	path = getPath(path)
	return path
		.split(/[\.[\]]/)
		.filter(Boolean)
		.reduce<string[]>((path, part, index, list) => {
			if (Number.isInteger(Number(part))) {
				return path
			}
			if (Number.isInteger(Number(list[index + 1]))) {
				return path.concat(`${part}[${list[index + 1]}]`)
			}
			return path.concat(part)
		}, [])
}

/**
 * return a PathInfo object resolving the parent PathInfo with a child PathInfo
 * WARNING the order of the argument is important parent must come first!
 *
 * @param parentPath
 * @param childPath
 */
export function resolvePathsAsPathInfo(
	parentPath: string | PathInfo,
	childPath: string | PathInfo
): PathInfo {
	const child = pathToArray(childPath)
	const parentArray = pathToArray(parentPath)
	const parent = [...parentArray].reverse()
	const pathInfo: PathInfo = {}

	const INDEX_REGEX = /\[[0-9]+\]$/gu

	function isMatchingPropertyName(a: string, b: string): boolean {
		// remove the index '[0]' from path to compare property name only
		return b.replace(new RegExp(INDEX_REGEX), '') === a.replace(new RegExp(INDEX_REGEX), '')
	}

	if (child.length > 0 && parent.length > 0) {
		while (isMatchingPropertyName(child[0], parent[0])) {
			child.shift()
			parent.shift()
			if (child.length === 0 || parent.length === 0) {
				break
			}
		}
	}
	const pathParts = parentArray.concat(child)
	const name = pathParts.pop()

	const lastPart = pathParts[pathParts.length - 1]
	const PROP_AND_INDEX_RE = /([^\[]+)\[([0-9]+)\]/u
	const result = PROP_AND_INDEX_RE.exec(lastPart)
	if (result) {
		pathParts[pathParts.length - 1] = result[1]
		pathInfo.index = Number(result[2])
	}

	pathInfo.name = name
	pathInfo.namespace = pathParts.join('.')
	return pathInfo
}
