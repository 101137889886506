import { get, isPlainObject, isEmpty } from 'lodash'
import { Parser, Grammar } from 'nearley'
import {
	default as grammar,
	flatten,
	QualifiedNameToken,
	Token
} from './generated/validation-rules.grammar'
import { pathWalker, getPath, PathInfo } from './path'
import { Context } from './Context'
import { Resolvable, valueResolverFactory } from './resolvable'
import {
	Expressable,
	processExpressable,
	processExpression,
	mergeDollarHelpers
} from './expressable'
import { parseTokens, addKeywordResolvers, ProcessNode } from './validationRuleParser'
import { isEmptyValue } from './isValueEmpty'
import { debug } from './debugInfo'

addKeywordResolvers({ required: requiredQualifiedNameResolver })

export interface Validatable extends Resolvable, Expressable {
	id?: string | number
	show?: boolean
	debug?: boolean
	disabled?: boolean
	required?: boolean
	isReadOnly?: boolean
	choices?: { value: string }[]
	validations: Validations
	value?: any
	skip?: boolean
}

export type Violations = Violation[]

export type Validations = { [rule: string]: string }

export interface Violation {
	node: Validatable
	message: string
	rule: string
	reason?: string
	value?: any
	path: string
}

export type Config = Validatable[] & { debug?: boolean }

export type ResultValid = undefined

export const Valid: ResultValid = undefined

export type ValidatorResult = ResultValid | ResultNotValid

export type ResultNotValid = {
	reason: string
	path: string
	node?: Validatable
	value?: any
	predicate?: string
}

export type Validator = (node: Validatable, context: Context) => ValidatorResult

export type ValidatableOptions = {
	skipValidatablesWithNoParent: boolean
}

export function defaultValidatableOptions(): ValidatableOptions {
	return { skipValidatablesWithNoParent: true }
}

const debugValidateInfo: {
	total: number
	processed: any[]
	counts: { [key: string]: number }
} = {
	total: 0,
	processed: [],
	counts: {}
}

/**
 * The entry point to user data validation.
 *
 * @param config The list of `Validatable` objects
 * @param context The data to be evaluated against the config
 * @param options @see ValidatableOptions
 * @returns list of `Violation` objects
 */
export function validate(
	config: Config,
	context: Context,
	options: ValidatableOptions = defaultValidatableOptions()
): Violations {
	const violations: Violations = []
	const { skipValidatablesWithNoParent } = options
	function iteratee(node: any) {
		context = mergeDollarHelpers(context, node, {
			config(): Config {
				return config
			}
		})
		function doesParentExist() {
			if (node.namespace) {
				const parent = get(context, node.namespace)
				return parent != null
			}
			return context != null
		}

		const isValidatable = !isEmpty(node.validations)
		const isExpressable = typeof node.expression === 'string'
		const shouldValidate =
			isValidatable && (skipValidatablesWithNoParent ? doesParentExist() : true)

		if (shouldValidate) {
			if (isExpressable) {
				node = processExpressable(context, node)
			}
			const shouldSkip =
				node?.show === false ||
				node?.disabled === true ||
				node?.readOnly === true ||
				node?.skip === true
			if (!shouldSkip) {
				validateNode(violations, context, node)
			}
		}
	}
	debug.addStartTimer(config, 'validateConfig')
	pathWalker(config, iteratee, context)
	debug.addStopTimer(config, 'validateConfig')
	/* istanbul ignore next */
	// if (shouldLogDebugInfo(config)) {
	// 	console.log({ debugValidateInfo })
	// 	console.log({ debugValidateConfig: config })
	// }
	// /* istanbul ignore next */
	// if (shouldLogDebugInfo(context)) {
	// 	console.log({ debugValidateContext: context })
	// }
	return violations
}

export function validateNode(
	violationsCollector: Violations,
	context: Context,
	node: Validatable
): void {
	const hasValidations = isPlainObject(node.validations)
	debug.addStartTimer(node, 'validateNode')
	if (hasValidations) {
		const entries = Object.entries(node.validations)
		entries.forEach(([rule, message]) => {
			if (isEmptyValue(rule)) {
				return
			}
			const validator: Validator = parseValidationRule(rule)
			// todo determine if we want config value to equal Context value
			node.value = valueResolverFactory(node.value)(node, context)
			const result = validator(node, context)
			if (result) {
				violationsCollector.push({ node, message, rule, ...result })
			}
		})
	}
	debug.addStopTimer(node, 'validateNode')
	const tag = (node as any).tag
	const key = `${getPath(node) ?? node.id ?? node.name}<${tag}>`
	debugValidateInfo.total++
	if (debugValidateInfo.counts[key]) {
		debugValidateInfo.counts[key]++
	} else {
		debugValidateInfo.counts[key] = 1
	}
	debugValidateInfo.processed.push(node)
	/* istanbul ignore next */
	if (context?.debug || node?.debug) {
		console.log({ debugValidateNode: node })
	}
}

function shouldLogDebugInfo(context?: { debug?: boolean }) {
	if (context?.hasOwnProperty('debug')) {
		return context.debug
	}
	return !process.env.CI
}

export function tokenizeRuleString(rule: string): Token[] {
	function parse() {
		try {
			const parser = new Parser(Grammar.fromCompiled(grammar))
			// the negate token 'not' requires space before and after in parser
			// this replace hack allows rule to be `not/foo/` for example...
			rule = rule.trim().replace(/^not ?/iu, ' not ')
			parser.feed(rule)
			return parser.finish()
		} catch (error) {
			/* istanbul ignore next */
			if (!process.env.CI) {
				console.log(error)
			}
			return []
		}
	}
	const tokens = parse()

	/* istanbul ignore next */
	if (Array.isArray(tokens)) {
		// if tokens.length > 1 then we have an ambiguous parser
		// todo determine if we need to fix this or not
		return flatten(tokens[0])
	}
	/* istanbul ignore next */
	return []
}

/**
 * Inject the value resolver if the rule starts with a comparator
 * @param tokens
 */
export function shouldInjectValueToken(tokens: Token[]): boolean {
	if (tokens.length === 0) {
		return false
	}
	if (tokens[0]?.name === 'less_than' && tokens[1]?.name === 'greater_than') {
		return true
	}
	if (tokens[0]?.type === 'negate_operator' && tokens[1]?.type === 'regular_expression') {
		return false
	}
	function whiteList({ type, value }: Token | undefined = { type: '' }): boolean {
		return /_operator$/.test(type) || (type === 'qname' && value === 'not')
	}
	function blackList({ type }: Token | undefined = { type: '' }): boolean {
		return !/^conditional_ternary_/.test(type)
	}
	const token = tokens[0]
	return blackList(token) && whiteList(token)
}

export function parseValidationRule(rule: string, throws: boolean = true): Validator {
	const tokens: Token[] = tokenizeRuleString(rule)
	if (process.env.NODE_ENV !== 'production') {
		if (tokens.length === 0 && rule.length > 0) {
			console.warn(
				`the rule "${rule}" did not parse any tokens. did you mix up the { [rule]: message } property? confirm the rule is correct.`
			)
		}
	}
	if (shouldInjectValueToken(tokens)) {
		tokens.unshift({ type: 'qname', value: 'value' })
	}
	const valueResolver = valueResolverFactory<any>()
	return function validateRule(node: Validatable, context: Context): ValidatorResult {
		const isRuleSkippable = !/tainted\(\)|^required$/.test(rule.trim())
		if (isRuleSkippable) {
			if (skipValidator(node)) {
				return Valid
			}
		}
		const inputValue = valueResolver(node, context)
		if (isRuleSkippable) {
			if (inputValue == null) {
				// undefined means skip this validation
				// the required value rule will handle this
				return Valid
			}
		}

		// first validation based on required property
		// not required and no value can be skipped too...
		if (node.required !== true && node.value === '') {
			return Valid
		}
		const predicate = parseTokens(tokens)(node, context)

		const result = processExpression(context, predicate, node)

		if (result) {
			return Valid
		}
		return {
			node,
			reason: rule,
			path: getPath(node),
			value: inputValue,
			predicate
		}
	}
}

export function skipValidator(node: Validatable): boolean {
	if (node.disabled === true) {
		// do not validate disabled fields
		return true
	}
	if (node.show === false) {
		// do not validate hidden fields
		return true
	}
	if (node.isReadOnly === true) {
		// do not validate read only fields
		return true
	}
	return false
}

export function requiredQualifiedNameResolver(token: QualifiedNameToken): ProcessNode {
	const valueResolver = valueResolverFactory<any>()
	return function validateRequired(node: any, context: any): string {
		if (skipValidator(node)) {
			return String(true)
		}
		if (node.required === false) {
			return String(true)
		}
		const inputValue = valueResolver(node, context)
		if (isEmptyValue(inputValue)) {
			return String(false)
		}
		return String(true)
	}
}

export function resolveConfig(config: Config, context: Context): Validatable[] {
	const fields: Validatable[] = []
	function iteratee(field: PathInfo) {
		fields.push(field as Validatable)
	}
	pathWalker(config, iteratee, context)
	return fields
}
