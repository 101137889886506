import { get } from 'lodash'
import { PathInfo, getPath } from './path'

/**
 * ValueResolver<T> can return undefined or value of type T.
 */
export type ValueResolver<T> = (node: Resolvable, context: any) => T | undefined

export interface Resolvable extends PathInfo {
	// todo implement a Format interface
	parseFormat?: string
}

export function valueResolverFactory<T>(initialValue: T | undefined = undefined): ValueResolver<T> {
	return function valueResolver(node: Resolvable, context: any): T | undefined {
		return getValue<T | undefined>(context, node, initialValue)
	}
}

export function getValue<T>(
	object: any,
	pathInfo: PathInfo | string,
	defaultValue: T | undefined = undefined
): T {
	const path = getPath(pathInfo)
	return get(object, path, defaultValue)
}
