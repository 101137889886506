// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
// Bypasses TS6133. Allow declared but unused functions.
// @ts-ignore
function id(d: any[]): any { return d[0]; }
 

export interface Token { type: string, name?: string, value?: any }

export interface PathExpressionToken extends Token {
	value: string
}

export interface OperatorToken extends Token {
	name: string 
	value: string
}

export interface QualifiedNameToken extends Token {
	value: string
}

export interface StringToken extends Token {
	quote: string | undefined
	value: string
}

export interface NumberToken extends Token {
	value: number 
}

export interface DateToken extends Token {
	name: string //'today' | 'now' | 'milliseconds'
	value: string | number
}

export interface RegularExpressionToken extends Token {
	value: string
	ignore?: boolean
}

export interface DateOperatorToken extends Token {
	name: string // 'minus' | 'plus'
	value: string
}

export interface DateOffsetToken extends Token {
	name: string //'number'
	value: number
}

export interface CalendarUnitToken extends Token {
	name: string // 'days' | 'weeks' | 'quarters' | 'years'
	value: string
}

export interface ParentheticalExpressionToken extends Token {
	expression: Token[]
}

export interface LogicalOperatorToken extends Token {
	name: string
	value: string
}

export interface DateExpressionToken extends Token {
	date: Token[] 
	operator: DateOperatorToken 
	dateOffset: NumberToken 
	calendarUnit: CalendarUnitToken
}

export interface TernaryIfToken extends Token {
	expression: Token[] 
	truthy: Token[]
	falsy: Token[]
}

export interface FunctionCallToken extends Token {
	name: string 
	parameters: Token[]
}

export interface PathToFunctionCallToken extends Token {
	path: PathExpressionToken
	functionCall: FunctionCallToken
	value: string
}

export interface MacroCallsToken extends Token {
	value: Array<LogicalOperatorToken | MacroCallToken>
}

export interface MacroCallToken extends Token {
	name: string 
	value: Token[]
}

export interface DateOffsetFromTodayToken extends Token {
	offsetFromToday: NumberToken
	calendarUnit: CalendarUnitToken 
}

export type PostProcess = (d: any[]) => any

export function date_offset_from_today(d: any[]): DateOffsetFromTodayToken {
	 //number _ calendar_unit
	 const offsetFromToday = d[0]
	 const calendarUnit = d[2] 
	 const value = join()(d)
	return { type: 'date_offset_from_today', offsetFromToday, calendarUnit, value }
}

export function ternary_if(d: any[]): TernaryIfToken {
	//	op _ parenthetical _ "?" _ parenthetical _ ":" _ parenthetical _ cp
	d = ignore(d)
	//	parenthetical "?" parenthetical ":" parenthetical
	const expression = flatten(d[0])
	const truthy = flatten(d[2])
	const falsy = flatten(d[4])
	return { type: 'ternary_if', expression, truthy, falsy }
}

export function function_call(d: any[]): FunctionCallToken {
	const name = String(join(0)(d))
	const parameters = flat(3)(d)
	const value = `${name}(${join()(parameters)})`
	return { type: 'function_call', name, parameters, value }
}

export function path_to_fuction_call(d: any[]): PathToFunctionCallToken {
	//path_expression "." function_call
	d = flatten(d)
	const path = d[0]
	const functionCall = d[2]
	const value = `${join()(path)}.${join()(functionCall)}`
	return { type: 'path_to_function_call', path, functionCall, value }
}

export function macro_calls(d: any[]): MacroCallsToken {
	//	macro_call _ (required_terminal | logical_operator) _ macro_calls
	const op = flatten(d[2])
	const operator = (op[0] == null) ? { type: 'logical_operator', name: 'and', value: '&&' } : op
	return { type: 'macro_calls', value: [d[0], operator, d[4]] }
}

export function macro_call(d: any[]): MacroCallToken {
	const name = join(0)(d)
	const value = flatten(d[3])
	return { type: 'macro_call', name, value }
}

export function ignore(d: any): any[] {
	if (Array.isArray(d)) {
		return d.filter((d) => d != null)
	}
	if (d != null) {
		return [d]
	}
	return []
}

export function flattenArray(list: any[], d: any): any {
	if (d == null) {
		return
	}
	if (Array.isArray(d)) {
		d.forEach((t) => flattenArray(list, t))
	} else {
		list.push(d)
	}
}

export function flatten(d: any): any[] {
	const list: any = []
	flattenArray(list, d)
	return list
}

export function flat(index: number | undefined = undefined): PostProcess {
	return function flatToken(d: any): any[] {
		if (index != undefined) {
			if (!isNaN(Number(index))) {
				d = d[(index)]
			}
		}
		return flatten(d)
	}
}

export function join(index: number | undefined = undefined): (d: any) => string {
	return function (d: any): string {
		if (index != undefined) {
			if (!isNaN(Number(index))) {
				d = d[(index)]
			}
		}
		return flatten(d).map((d) => {
			if (typeof d === 'string') {
				return d
			}
			if (Array.isArray(d)) {
				return join()(d)
			}
			if (Array.isArray(d?.value)) {
				return join()(d.value)
			}
			return d.value ?? ''
		}).join('')
	}
}

export function token(type: string, props: any, parseValue: PostProcess | any = id): PostProcess {
	const argCount = Array.from(arguments).length
	function resolve(d: any): any {
		return function (prop: any) {
			if (typeof prop === 'function') {
				return prop(d)
			}
			return prop
		}
	}
	function getValue(d: any): any {
		const value = resolve(d)
		if (argCount === 3) {
			return { value: value(parseValue) }
		}
		return { value: value(props) }
	}
	function getProps(d: any): any {
		if (argCount === 3) {
			const input = resolve(d)
			const value = input(props)
			if (typeof value === 'string') {
				return { name: value }
			}
			return value
		}
	}
	return function resolveToken(d: any): any {
		const token = { type, ...getProps(d), ...getValue(d) }
		return token
	}
}

export function skip(d: any): any {
	return null
}


interface NearleyToken {
  value: any;
  [key: string]: any;
};

interface NearleyLexer {
  reset: (chunk: string, info: any) => void;
  next: () => NearleyToken | undefined;
  save: () => any;
  formatError: (token: never) => string;
  has: (tokenType: string) => boolean;
};

interface NearleyRule {
  name: string;
  symbols: NearleySymbol[];
  postprocess?: (d: any[], loc?: number, reject?: {}) => any;
};

type NearleySymbol = string | { literal: any } | { test: (token: any) => boolean };

interface Grammar {
  Lexer: NearleyLexer | undefined;
  ParserRules: NearleyRule[];
  ParserStart: string;
};

const grammar: Grammar = {
  Lexer: undefined,
  ParserRules: [
    {"name": "rule$ebnf$1", "symbols": ["assign_operator"], "postprocess": id},
    {"name": "rule$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "rule", "symbols": ["_", "rule$ebnf$1", "_", "ternary_if", "_", "optional_terminal", "_"], "postprocess": flatten},
    {"name": "ternary_if", "symbols": ["op", "_", "parenthetical", "_", {"literal":"?"}, "_", "parenthetical", "_", {"literal":":"}, "_", "parenthetical", "_", "cp"], "postprocess": ternary_if},
    {"name": "ternary_if", "symbols": ["parenthetical", "_", {"literal":"?"}, "_", "parenthetical", "_", {"literal":":"}, "_", "parenthetical"], "postprocess": ternary_if},
    {"name": "ternary_if", "symbols": ["parenthetical"]},
    {"name": "ternary_if", "symbols": ["macro_calls"]},
    {"name": "parenthetical", "symbols": ["op", "_", "parenthetical", "_", "cp"], "postprocess": token('parenthetical_expression', flat(2))},
    {"name": "parenthetical", "symbols": ["operator", "_", "parenthetical"]},
    {"name": "parenthetical", "symbols": ["expression"]},
    {"name": "expression", "symbols": ["parenthetical", "_", "operator", "_", "parenthetical"]},
    {"name": "expression", "symbols": ["value"]},
    {"name": "value", "symbols": ["date_offset_from_today"]},
    {"name": "value", "symbols": ["string"]},
    {"name": "value", "symbols": ["path_to_fuction_call"]},
    {"name": "value", "symbols": ["regexp"]},
    {"name": "value", "symbols": ["function_call"]},
    {"name": "date_offset_from_today", "symbols": ["number", "_", "calendar_unit"], "postprocess": date_offset_from_today},
    {"name": "date_offset_from_today", "symbols": ["percentage"]},
    {"name": "calendar_unit$subexpression$1", "symbols": [{"literal":"d"}]},
    {"name": "calendar_unit$subexpression$1$string$1", "symbols": [{"literal":"d"}, {"literal":"a"}, {"literal":"y"}], "postprocess": (d) => d.join('')},
    {"name": "calendar_unit$subexpression$1", "symbols": ["calendar_unit$subexpression$1$string$1"]},
    {"name": "calendar_unit$subexpression$1$string$2", "symbols": [{"literal":"d"}, {"literal":"a"}, {"literal":"y"}, {"literal":"s"}], "postprocess": (d) => d.join('')},
    {"name": "calendar_unit$subexpression$1", "symbols": ["calendar_unit$subexpression$1$string$2"]},
    {"name": "calendar_unit", "symbols": ["calendar_unit$subexpression$1"], "postprocess": token('calendar_unit', 'days', (d: any[]) => d[0][0])},
    {"name": "calendar_unit$subexpression$2", "symbols": [{"literal":"w"}]},
    {"name": "calendar_unit$subexpression$2$string$1", "symbols": [{"literal":"w"}, {"literal":"e"}, {"literal":"e"}, {"literal":"k"}], "postprocess": (d) => d.join('')},
    {"name": "calendar_unit$subexpression$2", "symbols": ["calendar_unit$subexpression$2$string$1"]},
    {"name": "calendar_unit$subexpression$2$string$2", "symbols": [{"literal":"w"}, {"literal":"e"}, {"literal":"e"}, {"literal":"k"}, {"literal":"s"}], "postprocess": (d) => d.join('')},
    {"name": "calendar_unit$subexpression$2", "symbols": ["calendar_unit$subexpression$2$string$2"]},
    {"name": "calendar_unit", "symbols": ["calendar_unit$subexpression$2"], "postprocess": token('calendar_unit', 'weeks', (d: any[]) => d[0][0])},
    {"name": "calendar_unit$subexpression$3", "symbols": [{"literal":"M"}]},
    {"name": "calendar_unit$subexpression$3$string$1", "symbols": [{"literal":"m"}, {"literal":"o"}, {"literal":"n"}, {"literal":"t"}, {"literal":"h"}], "postprocess": (d) => d.join('')},
    {"name": "calendar_unit$subexpression$3", "symbols": ["calendar_unit$subexpression$3$string$1"]},
    {"name": "calendar_unit$subexpression$3$string$2", "symbols": [{"literal":"m"}, {"literal":"o"}, {"literal":"n"}, {"literal":"t"}, {"literal":"h"}, {"literal":"s"}], "postprocess": (d) => d.join('')},
    {"name": "calendar_unit$subexpression$3", "symbols": ["calendar_unit$subexpression$3$string$2"]},
    {"name": "calendar_unit", "symbols": ["calendar_unit$subexpression$3"], "postprocess": token('calendar_unit', 'months', (d: any[]) => d[0][0])},
    {"name": "calendar_unit$subexpression$4", "symbols": [{"literal":"q"}]},
    {"name": "calendar_unit$subexpression$4$string$1", "symbols": [{"literal":"q"}, {"literal":"u"}, {"literal":"a"}, {"literal":"r"}, {"literal":"t"}, {"literal":"e"}, {"literal":"r"}], "postprocess": (d) => d.join('')},
    {"name": "calendar_unit$subexpression$4", "symbols": ["calendar_unit$subexpression$4$string$1"]},
    {"name": "calendar_unit$subexpression$4$string$2", "symbols": [{"literal":"q"}, {"literal":"u"}, {"literal":"a"}, {"literal":"r"}, {"literal":"t"}, {"literal":"e"}, {"literal":"r"}, {"literal":"s"}], "postprocess": (d) => d.join('')},
    {"name": "calendar_unit$subexpression$4", "symbols": ["calendar_unit$subexpression$4$string$2"]},
    {"name": "calendar_unit", "symbols": ["calendar_unit$subexpression$4"], "postprocess": token('calendar_unit', 'quarters', (d: any[]) => d[0][0])},
    {"name": "calendar_unit$subexpression$5", "symbols": [{"literal":"y"}]},
    {"name": "calendar_unit$subexpression$5$string$1", "symbols": [{"literal":"y"}, {"literal":"e"}, {"literal":"a"}, {"literal":"r"}], "postprocess": (d) => d.join('')},
    {"name": "calendar_unit$subexpression$5", "symbols": ["calendar_unit$subexpression$5$string$1"]},
    {"name": "calendar_unit$subexpression$5$string$2", "symbols": [{"literal":"y"}, {"literal":"e"}, {"literal":"a"}, {"literal":"r"}, {"literal":"s"}], "postprocess": (d) => d.join('')},
    {"name": "calendar_unit$subexpression$5", "symbols": ["calendar_unit$subexpression$5$string$2"]},
    {"name": "calendar_unit", "symbols": ["calendar_unit$subexpression$5"], "postprocess": token('calendar_unit', 'years', (d: any[]) => d[0][0])},
    {"name": "path_to_fuction_call", "symbols": ["path_expression", {"literal":"."}, "function_call"], "postprocess": path_to_fuction_call},
    {"name": "path_to_fuction_call", "symbols": ["path_expression"]},
    {"name": "path_expression", "symbols": ["path_part", "path_sep", "path_expression"], "postprocess": token('path_expression', join())},
    {"name": "path_expression", "symbols": ["path_part"]},
    {"name": "path_part$subexpression$1", "symbols": ["path_number_index"]},
    {"name": "path_part$subexpression$1", "symbols": ["path_string_index"]},
    {"name": "path_part", "symbols": ["qname", "path_part$subexpression$1"]},
    {"name": "path_part", "symbols": ["qname"]},
    {"name": "path_number_index", "symbols": [{"literal":"["}, "_", "number", "_", {"literal":"]"}]},
    {"name": "path_string_index", "symbols": [{"literal":"["}, "_", "string", "_", {"literal":"]"}]},
    {"name": "path_string_index", "symbols": [{"literal":"["}, "_", "string", "_", {"literal":"]"}]},
    {"name": "path_sep", "symbols": [{"literal":"."}]},
    {"name": "function_call$ebnf$1", "symbols": ["parameters"], "postprocess": id},
    {"name": "function_call$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "function_call", "symbols": ["qname", {"literal":"("}, "_", "function_call$ebnf$1", "_", {"literal":")"}], "postprocess": function_call},
    {"name": "parameters", "symbols": ["ternary_if", "_", "comma", "_", "ternary_if"]},
    {"name": "parameters", "symbols": ["ternary_if"]},
    {"name": "macro_calls$subexpression$1", "symbols": [{"literal":";"}], "postprocess": skip},
    {"name": "macro_calls$subexpression$1", "symbols": ["logical_operator"]},
    {"name": "macro_calls", "symbols": ["macro_call", "_", "macro_calls$subexpression$1", "_", "macro_calls"], "postprocess": ignore},
    {"name": "macro_calls", "symbols": ["macro_call"]},
    {"name": "macro_call$ebnf$1", "symbols": [/[a-zA-Z]/]},
    {"name": "macro_call$ebnf$1", "symbols": ["macro_call$ebnf$1", /[a-zA-Z]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "macro_call", "symbols": ["macro_call$ebnf$1", {"literal":":"}, "_", "ternary_if"], "postprocess": macro_call},
    {"name": "operator", "symbols": ["negate_operator"]},
    {"name": "operator", "symbols": ["logical_operator"]},
    {"name": "operator", "symbols": ["comparison_operator"]},
    {"name": "operator", "symbols": ["arithmetic_operator"]},
    {"name": "negate_operator", "symbols": [{"literal":"!"}], "postprocess": token('negate_operator', 'negate', '!')},
    {"name": "negate_operator$string$1", "symbols": [{"literal":" "}, {"literal":"n"}, {"literal":"o"}, {"literal":"t"}, {"literal":" "}], "postprocess": (d) => d.join('')},
    {"name": "negate_operator", "symbols": ["negate_operator$string$1"], "postprocess": token('negate_operator', 'negate', '!')},
    {"name": "logical_operator$string$1", "symbols": [{"literal":"|"}, {"literal":"|"}], "postprocess": (d) => d.join('')},
    {"name": "logical_operator", "symbols": ["logical_operator$string$1"], "postprocess": token('logical_operator', 'or', '||')},
    {"name": "logical_operator$string$2", "symbols": [{"literal":" "}, {"literal":"o"}, {"literal":"r"}, {"literal":" "}], "postprocess": (d) => d.join('')},
    {"name": "logical_operator", "symbols": ["logical_operator$string$2"], "postprocess": token('logical_operator', 'or', '||')},
    {"name": "logical_operator$string$3", "symbols": [{"literal":"&"}, {"literal":"&"}], "postprocess": (d) => d.join('')},
    {"name": "logical_operator", "symbols": ["logical_operator$string$3"], "postprocess": token('logical_operator', 'and', '&&')},
    {"name": "logical_operator$string$4", "symbols": [{"literal":" "}, {"literal":"a"}, {"literal":"n"}, {"literal":"d"}, {"literal":" "}], "postprocess": (d) => d.join('')},
    {"name": "logical_operator", "symbols": ["logical_operator$string$4"], "postprocess": token('logical_operator', 'and', '&&')},
    {"name": "comparison_operator$string$1", "symbols": [{"literal":"="}, {"literal":"="}], "postprocess": (d) => d.join('')},
    {"name": "comparison_operator", "symbols": ["comparison_operator$string$1"], "postprocess": token('comparison_operator', 'equal', '==')},
    {"name": "comparison_operator$string$2", "symbols": [{"literal":"="}, {"literal":"="}, {"literal":"="}], "postprocess": (d) => d.join('')},
    {"name": "comparison_operator", "symbols": ["comparison_operator$string$2"], "postprocess": token('comparison_operator', 'equal_strict', '==')},
    {"name": "comparison_operator", "symbols": [{"literal":"<"}], "postprocess": token('comparison_operator', 'less_than', '<')},
    {"name": "comparison_operator", "symbols": [{"literal":">"}], "postprocess": token('comparison_operator', 'greater_than', '>')},
    {"name": "comparison_operator$string$3", "symbols": [{"literal":"<"}, {"literal":"="}], "postprocess": (d) => d.join('')},
    {"name": "comparison_operator", "symbols": ["comparison_operator$string$3"], "postprocess": token('comparison_operator', 'less_than_or_equal', '<=')},
    {"name": "comparison_operator$string$4", "symbols": [{"literal":">"}, {"literal":"="}], "postprocess": (d) => d.join('')},
    {"name": "comparison_operator", "symbols": ["comparison_operator$string$4"], "postprocess": token('comparison_operator', 'greater_than_or_equal', '>=')},
    {"name": "comparison_operator$string$5", "symbols": [{"literal":"!"}, {"literal":"="}], "postprocess": (d) => d.join('')},
    {"name": "comparison_operator", "symbols": ["comparison_operator$string$5"], "postprocess": token('comparison_operator', 'not_equal', '!=')},
    {"name": "comparison_operator$string$6", "symbols": [{"literal":" "}, {"literal":"n"}, {"literal":"o"}, {"literal":"t"}], "postprocess": (d) => d.join('')},
    {"name": "comparison_operator", "symbols": ["comparison_operator$string$6", "_", {"literal":"="}], "postprocess": token('comparison_operator', 'not_equal', '!=')},
    {"name": "assign_operator", "symbols": [{"literal":"="}], "postprocess": token('assign_operator', 'assign', '=')},
    {"name": "arithmetic_operator", "symbols": ["minus"]},
    {"name": "arithmetic_operator", "symbols": ["plus"]},
    {"name": "arithmetic_operator", "symbols": ["division"]},
    {"name": "arithmetic_operator", "symbols": ["multiply"]},
    {"name": "division", "symbols": [{"literal":"/"}], "postprocess": token('arithmetic_operator', 'divide', '/')},
    {"name": "division$string$1", "symbols": [{"literal":" "}, {"literal":"d"}, {"literal":"i"}, {"literal":"v"}, {"literal":"i"}, {"literal":"d"}, {"literal":"e"}, {"literal":" "}], "postprocess": (d) => d.join('')},
    {"name": "division", "symbols": ["division$string$1"], "postprocess": token('arithmetic_operator', 'divide', '/')},
    {"name": "multiply", "symbols": [{"literal":"*"}], "postprocess": token('arithmetic_operator', 'multiply', '*')},
    {"name": "multiply$string$1", "symbols": [{"literal":" "}, {"literal":"m"}, {"literal":"u"}, {"literal":"l"}, {"literal":"t"}, {"literal":"i"}, {"literal":"p"}, {"literal":"l"}, {"literal":"y"}, {"literal":" "}], "postprocess": (d) => d.join('')},
    {"name": "multiply", "symbols": ["multiply$string$1"], "postprocess": token('arithmetic_operator', 'multiply', '*')},
    {"name": "minus", "symbols": [{"literal":"-"}], "postprocess": token('arithmetic_operator', 'minus', '-')},
    {"name": "minus$string$1", "symbols": [{"literal":" "}, {"literal":"m"}, {"literal":"i"}, {"literal":"n"}, {"literal":"u"}, {"literal":"s"}, {"literal":" "}], "postprocess": (d) => d.join('')},
    {"name": "minus", "symbols": ["minus$string$1"], "postprocess": token('arithmetic_operator', 'minus', '-')},
    {"name": "plus", "symbols": [{"literal":"+"}], "postprocess": token('arithmetic_operator', 'plus', '+')},
    {"name": "plus$string$1", "symbols": [{"literal":" "}, {"literal":"p"}, {"literal":"l"}, {"literal":"u"}, {"literal":"s"}, {"literal":" "}], "postprocess": (d) => d.join('')},
    {"name": "plus", "symbols": ["plus$string$1"], "postprocess": token('arithmetic_operator', 'plus', '+')},
    {"name": "regexp$ebnf$1", "symbols": [/[^]/]},
    {"name": "regexp$ebnf$1", "symbols": ["regexp$ebnf$1", /[^]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "regexp", "symbols": [{"literal":"/"}, "regexp$ebnf$1", {"literal":"/"}], "postprocess": token('regular_expression', join(1))},
    {"name": "regexp$ebnf$2", "symbols": [/[^]/]},
    {"name": "regexp$ebnf$2", "symbols": ["regexp$ebnf$2", /[^]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "regexp$string$1", "symbols": [{"literal":"/"}, {"literal":"."}, {"literal":"i"}], "postprocess": (d) => d.join('')},
    {"name": "regexp", "symbols": [{"literal":"/"}, "regexp$ebnf$2", "regexp$string$1"], "postprocess": token('regular_expression', {ignore: true}, join(1))},
    {"name": "regexp$ebnf$3", "symbols": [/[^]/]},
    {"name": "regexp$ebnf$3", "symbols": ["regexp$ebnf$3", /[^]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "regexp$string$2", "symbols": [{"literal":"/"}, {"literal":"."}, {"literal":"i"}, {"literal":"g"}, {"literal":"n"}, {"literal":"o"}, {"literal":"r"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "regexp", "symbols": [{"literal":"/"}, "regexp$ebnf$3", "regexp$string$2"], "postprocess": token('regular_expression', {ignore: true}, join(1))},
    {"name": "regexp$ebnf$4", "symbols": [/[^]/]},
    {"name": "regexp$ebnf$4", "symbols": ["regexp$ebnf$4", /[^]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "regexp$string$3", "symbols": [{"literal":"/"}, {"literal":"."}, {"literal":"i"}, {"literal":"g"}, {"literal":"n"}, {"literal":"o"}, {"literal":"r"}, {"literal":"e"}, {"literal":"C"}, {"literal":"a"}, {"literal":"s"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "regexp", "symbols": [{"literal":"/"}, "regexp$ebnf$4", "regexp$string$3"], "postprocess": token('regular_expression', {ignore: true}, join(1))},
    {"name": "regexp$ebnf$5", "symbols": [/[^]/]},
    {"name": "regexp$ebnf$5", "symbols": ["regexp$ebnf$5", /[^]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "regexp", "symbols": [{"literal":"^"}, "regexp$ebnf$5", {"literal":"$"}], "postprocess": token('regular_expression', join())},
    {"name": "regexp$ebnf$6", "symbols": [/[^]/]},
    {"name": "regexp$ebnf$6", "symbols": ["regexp$ebnf$6", /[^]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "regexp$string$4", "symbols": [{"literal":"$"}, {"literal":"."}, {"literal":"i"}], "postprocess": (d) => d.join('')},
    {"name": "regexp", "symbols": [{"literal":"^"}, "regexp$ebnf$6", "regexp$string$4"], "postprocess": token('regular_expression', {ignore: true}, (d: any[]) => '^' + join(1)(d) + '$')},
    {"name": "regexp$ebnf$7", "symbols": [/[^]/]},
    {"name": "regexp$ebnf$7", "symbols": ["regexp$ebnf$7", /[^]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "regexp$string$5", "symbols": [{"literal":"$"}, {"literal":"."}, {"literal":"i"}, {"literal":"g"}, {"literal":"n"}, {"literal":"o"}, {"literal":"r"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "regexp", "symbols": [{"literal":"^"}, "regexp$ebnf$7", "regexp$string$5"], "postprocess": token('regular_expression', {ignore: true}, (d: any[]) => '^' + join(1)(d) + '$')},
    {"name": "regexp$ebnf$8", "symbols": [/[^]/]},
    {"name": "regexp$ebnf$8", "symbols": ["regexp$ebnf$8", /[^]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "regexp$string$6", "symbols": [{"literal":"$"}, {"literal":"."}, {"literal":"i"}, {"literal":"g"}, {"literal":"n"}, {"literal":"o"}, {"literal":"r"}, {"literal":"e"}, {"literal":"C"}, {"literal":"a"}, {"literal":"s"}, {"literal":"e"}], "postprocess": (d) => d.join('')},
    {"name": "regexp", "symbols": [{"literal":"^"}, "regexp$ebnf$8", "regexp$string$6"], "postprocess": token('regular_expression', {ignore: true}, (d: any[]) => '^' + join(1)(d) + '$')},
    {"name": "percentage", "symbols": ["number", "_", {"literal":"%"}], "postprocess": token('number', (d: any[]) => d[0].value / 100)},
    {"name": "percentage", "symbols": ["number"]},
    {"name": "number", "symbols": ["pos_integer", {"literal":"."}, "pos_integer"], "postprocess": token('number', (d: any[]) => Number(`${d[0]}.${d[2]}`))},
    {"name": "number", "symbols": ["pos_integer"], "postprocess": token('number', id)},
    {"name": "pos_integer$ebnf$1", "symbols": [/[\d]/]},
    {"name": "pos_integer$ebnf$1", "symbols": ["pos_integer$ebnf$1", /[\d]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "pos_integer", "symbols": ["pos_integer$ebnf$1"], "postprocess": (d: any[]) => Number(join()(d))},
    {"name": "string$ebnf$1", "symbols": []},
    {"name": "string$ebnf$1", "symbols": ["string$ebnf$1", /[^]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "string", "symbols": [{"literal":"'"}, "string$ebnf$1", {"literal":"'"}], "postprocess": token('string', { quote: 'single' }, join())},
    {"name": "string$ebnf$2", "symbols": []},
    {"name": "string$ebnf$2", "symbols": ["string$ebnf$2", /[^]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "string", "symbols": [{"literal":"\""}, "string$ebnf$2", {"literal":"\""}], "postprocess": token('string', { quote: 'double' }, join())},
    {"name": "qname$ebnf$1", "symbols": [/[a-zA-Z_$]/]},
    {"name": "qname$ebnf$1", "symbols": ["qname$ebnf$1", /[a-zA-Z_$]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "qname", "symbols": ["qname$ebnf$1"], "postprocess": token('qname', join(0))},
    {"name": "required_terminal$ebnf$1", "symbols": [/[ \n;]/]},
    {"name": "required_terminal$ebnf$1", "symbols": ["required_terminal$ebnf$1", /[ \n;]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "required_terminal", "symbols": ["required_terminal$ebnf$1"], "postprocess": skip},
    {"name": "optional_terminal$ebnf$1", "symbols": []},
    {"name": "optional_terminal$ebnf$1", "symbols": ["optional_terminal$ebnf$1", {"literal":";"}], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "optional_terminal", "symbols": ["optional_terminal$ebnf$1"], "postprocess": skip},
    {"name": "comma", "symbols": [{"literal":","}], "postprocess": skip},
    {"name": "op", "symbols": [{"literal":"("}], "postprocess": skip},
    {"name": "cp", "symbols": [{"literal":")"}], "postprocess": skip},
    {"name": "_$ebnf$1", "symbols": []},
    {"name": "_$ebnf$1", "symbols": ["_$ebnf$1", /[ \s]/], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "_", "symbols": ["_$ebnf$1"], "postprocess": skip}
  ],
  ParserStart: "rule",
};

export default grammar;
