import { name, version } from '../package.json'
export * from './validatable'
export * from './resolvable'
export * from './expressable'
export * from './path'
export * from './Context'
export * from './validationRuleParser'
export * from './sortByPropsFactory'
export * from './debugInfo'
export * from './dateTime'
;(function versions(name: string, version: string, window: any) {
	const versions = (window.versions = window.versions || {})
	/* istanbul ignore next */
	const values = new Set(versions[name])
	values.add(version)
	versions[name] = Array.from(values)
})(name, version, window)
