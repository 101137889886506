import { camelCase } from 'lodash'

// todo move this module into jest helpers or its own module
// todo refactor to use the javascript console API which does all this

const DEBUG_INFO = Symbol('debug-info')

export type DebugInfo = { messages: string[]; [name: string]: any }

export function getDebugInfo(node: any): DebugInfo {
	if (!node[DEBUG_INFO]) {
		addDebugInfoProperty(node, { messages: [] })
	}
	return node[DEBUG_INFO]
}

export function debugInfoMessage(node: any, message: string): void {
	getDebugInfo(node).messages.push(message)
}

export function debugInfoProp(node: any, props: { [name: string]: any }): void {
	addDebugInfoProperty(node, { ...getDebugInfo(node), ...props })
}

export const debug = {
	getDebugInfo(node: any) {
		return getDebugInfo(node)
	},
	addStartTimer(node: any, name: string, ...args: any) {
		const info = getDebugInfo(node)
		const timerId = camelCase(`${name}-begin`)
		const timer = { [timerId]: Date.now() }
		addDebugInfoProperty(node, { ...info, ...timer })
		if (args.length > 0) {
			debug.addInfo(node, args)
		}
	},
	addStopTimer(node: any, name: string, ...args: any) {
		const info = getDebugInfo(node)
		const beginTimerId = camelCase(`${name}-begin`)
		const completedTimerId = camelCase(`${name}-completed`)
		const durationId = camelCase(`${name}-duration`)
		const now = Date.now()
		const duration = now - info[beginTimerId]
		const timer = { [completedTimerId]: now, [durationId]: duration }
		addDebugInfoProperty(node, { ...info, ...timer })
		if (args.length > 0) {
			debug.addInfo(node, args)
		}
	},
	addInfo(node: any, ...args: any[]) {
		args.forEach((arg) => {
			if (arg === node) {
				return // don't create a circular reference
			}
			if (typeof arg === 'string') {
				debugInfoMessage(node, arg)
			} else {
				debugInfoProp(node, arg)
			}
		})
	}
}

export function addDebugInfoProperty(object: any, value?: any): void {
	if (Object.isExtensible(object)) {
		Object.defineProperty(object, DEBUG_INFO, {
			enumerable: false,
			configurable: true,
			writable: true,
			value
		})
	}
}

export default debug
