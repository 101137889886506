import { isEmpty, isPlainObject } from 'lodash'

export function isEmptyValue(value: any): boolean {
	if (value == null || value === '') {
		return true
	}
	if (Array.isArray(value) || isPlainObject(value)) {
		return isEmpty(value)
	}
	return false
}
