import { evaluate } from 'bcx-expression-evaluator'
import { get, trim } from 'lodash'
import { Context } from './Context'
import { debug } from './debugInfo'
import { getNamespace, getParentPath, getPath } from './path'

export type Expression = string

export interface Expressable {
	debug?: boolean
	expression?: Expression
}

const options = { debug: true, throwErrors: false }

export function setDebugMode(debug: boolean) {
	options.debug = debug
}

export function setThrowErrors(throwErrors: boolean) {
	options.throwErrors = throwErrors
}

export function processExpressable(context: Context, node: Expressable): any {
	const { expression } = node
	if (typeof expression !== 'string') {
		return node
	}
	if (expression === '') {
		return node
	}
	debug.addStartTimer(node, 'processExpression')
	function ensureNotUndefined(node: any) {
		if (node == null) {
			return {}
		}
		return node
	}
	const nodeClone = ensureNotUndefined(node)
	const contextFrozen = ensureNotUndefined(context)
	const expressions = expression.split(/;|\n/g).map(trim).filter(Boolean)

	try {
		expressions.forEach((exp) => processExpression(contextFrozen, exp, nodeClone))
	} catch (error) {
		if (options.throwErrors) {
			debug.addStopTimer(nodeClone, 'processExpression')
			throw error
		}
	} finally {
		debug.addStopTimer(nodeClone, 'processExpression')
	}

	/* istanbul ignore next */
	if (context?.debug || nodeClone?.debug) {
		console.log({
			debugProcessExpressableContext: debug.getDebugInfo(context),
			debugProcessExpressableNode: nodeClone
		})
	}

	return nodeClone
}

export function processExpression(context: Context, expression: string, node: any): any {
	/* istanbul ignore next */
	const isDebugMode = options.debug || context?.debug || node?.debug

	context = mergeDollarHelpers(context, node)

	try {
		const result = evaluate(expression, node, context)

		/* istanbul ignore next */
		if (isDebugMode) {
			debug.addInfo(node, `evaluate: "${expression}" result: ${JSON.stringify(result)}`)
		}

		return result
	} catch (error) {
		if (options.throwErrors) {
			throw error
		}

		/* istanbul ignore next */
		if (isDebugMode) {
			console.error(error)
		}
	}
}

export type DollarHelperFunctions = {
	/** get the value for a given path optionaly provide a default value */
	value: (path: string, defaultValue?: any) => any
	/** get the parent object path aware - used to access properies of an object in context */
	parent: any
	/** get the item group path aware - used to access array of items  */
	group: any
	/** get the sum of a field specified by path - if parent is in an item group will sum field in group */
	sum: (path: string | undefined) => number
	/** get the field namespace value */
	getNamespace: (arg: any | undefined) => string
	/** get the field parent path */
	getParentPath: (arg: any | undefined) => string
	/** get the field path */
	getPath: (arg: any | undefined) => string
	// allow consumers to add their own helpers
	[name: string]: any
}

export type DollarHelpers = {
	$: DollarHelperFunctions
}

export function mergeDollarHelpers(
	context: Context,
	node: any,
	helpers: { [helper: string]: any } = {}
): Context & DollarHelpers {
	const data = { ...context, ...node }
	const nodePath = getPath(node)
	return {
		...context,
		$: {
			...helpers,
			value(path: string, defaultValue?: any): any {
				path = path ?? nodePath
				return get(data, path, defaultValue)
			},
			get parent(): any {
				const ns = getNamespace(node)
				if (ns) {
					return get(context, ns, context)
				}
				return context
			},
			get group(): any {
				const ns = getNamespace(node).replace(/\[\d+\]$/, '')
				if (ns) {
					return get(context, ns, [])
				}
				return []
			},
			sum(path: string | undefined): number {
				const list = this.group
				const name = path ?? node?.name
				if (Array.isArray(list)) {
					const sum = list.reduce((sum, item) => {
						const itemValue = Number(get(item, name, 0))
						const fieldValue = isNaN(itemValue) ? 0 : itemValue
						return sum + fieldValue
					}, 0)
					debug.addInfo(node, `$.sum(${name}) = ${sum}`)
					return sum
				}
				return 0
			},
			getNamespace(arg: any | undefined): string {
				return getNamespace(arg ?? node)
			},
			getParentPath(arg: any | undefined): string {
				return getParentPath(arg ?? node)
			},
			getPath(arg: any | undefined): string {
				return getPath(arg ?? node)
			}
		}
	}
}
