import { isPlainObject } from 'lodash'
import { DateTime, DateObject } from 'luxon'
import {
	Token,
	DateToken,
	DateExpressionToken,
	StringToken,
	RegularExpressionToken,
	TernaryIfToken,
	FunctionCallToken,
	MacroCallToken,
	ParentheticalExpressionToken,
	QualifiedNameToken,
	DateOperatorToken,
	DateOffsetFromTodayToken,
	PathToFunctionCallToken,
	MacroCallsToken
} from './generated/validation-rules.grammar'
import { processExpression } from './expressable'
import { getValue, Resolvable, valueResolverFactory } from './resolvable'
import { debug } from './debugInfo'
import { isEmptyValue } from './isValueEmpty'

const macroCallResolvers: { [name: string]: Resolver<MacroCallToken> } = {
	iif: iifMacroCallResolver,
	min: minMacroCallResolver,
	max: maxMacroCallResolver,
	sameAs: sameAsMacroCallResolver
}

const functionCalResolvers: { [name: string]: Resolver<FunctionCallToken> } = {
	sum: sumFunctionCallResolver,
	date: dateFunctionCallResolver,
	tainted: taintedFunctionCallResolver,
	value: valueFunctionCallResolver,
	length: lengthFunctionCallResolver
}

const qualifiedNameResolvers: {
	[name: string]: Resolver<QualifiedNameToken>
} = {
	today: todayQualifiedNameResolver,
	now: nowQualifiedNameResolver,
	true: booleanQualifiedNameResolver,
	false: booleanQualifiedNameResolver
}

export type KeywordResolvers = {
	[name: string]: Resolver<QualifiedNameToken>
}

export function addKeywordResolvers(resolvers: KeywordResolvers): void {
	Object.entries(resolvers).forEach(([name, resolver]) => {
		qualifiedNameResolvers[name] = resolver
	})
}

export type MacroCallResolvers = {
	[name: string]: Resolver<MacroCallToken>
}

/* istanbul ignore next */
export function addMacroCallResolvers(resolvers: MacroCallResolvers): void {
	Object.entries(resolvers).forEach(([name, resolver]) => {
		macroCallResolvers[name] = resolver
	})
}

export type FunctionCallResolvers = {
	[name: string]: Resolver<FunctionCallToken>
}

/* istanbul ignore next */
export function addFunctionCallResolvers(resolvers: FunctionCallResolvers): void {
	Object.entries(resolvers).forEach(([name, resolver]) => {
		functionCalResolvers[name] = resolver
	})
}

export type ProcessNode = (node: any, context: any) => string

export type Resolver<T extends Token> = (token: T) => ProcessNode

export function parse(token?: Token | Token[]): ProcessNode {
	if (Array.isArray(token)) {
		return parseTokens(token)
	}
	return parseToken(token)
}

export type Tokenizer = (tokens: Token[], currentIndex: number) => TokenizerResult
export type TokenizerResult = [number, ProcessNode | ProcessNode[] | undefined]

export function consumeToken(tokens: Token[], currentIndex: number): TokenizerResult {
	return [1, parseToken(tokens[currentIndex])]
}

export function tokenizeKeywordFromQName(tokens: Token[], currentIndex: number): TokenizerResult {
	const token = tokens[currentIndex]
	if (token.type === 'qname' && token.value === 'not') {
		return [1, parseToken({ type: 'negate_operator', value: '!' })]
	}
	return [0, undefined]
}

export function tokenizeLessThanGreaterThanAsNotEqual(
	tokens: Token[],
	currentIndex: number
): TokenizerResult {
	const token = tokens[currentIndex]
	const token2 = tokens[currentIndex + 1]
	if (token.type === 'comparison_operator' && token.name === 'less_than') {
		if (token2.type === 'comparison_operator' && token2.name === 'greater_than') {
			return [
				2,
				parseToken({
					type: 'comparison_operator',
					name: 'not_equal',
					value: '!='
				})
			]
		}
	}
	return [0, undefined]
}

export function tokenizeMacroCalls(tokens: Token[], currentIndex: number): TokenizerResult {
	let isConsecutiveMacroCalls = true
	tokens = tokens.slice(currentIndex).filter(({ type }) => {
		if (isConsecutiveMacroCalls === false) {
			return false
		}
		return (isConsecutiveMacroCalls = 'macro_call' === type)
	})

	const tokenized = tokens
		.reduce<Array<Token>>((result, token, index, { length }) => {
			result.push(token)
			if (index < length - 1) {
				result.push({ type: 'logical_operator', name: 'and', value: '&&' })
			}
			return result
		}, [])
		.map(parseToken)

	return [tokens.length, tokenized]
}

export function tokenizeDateOffset(tokens: Token[], currentIndex: number): TokenizerResult {
	const token = tokens[currentIndex]
	const nextToken = tokens[currentIndex + 1]
	if ('arithmetic_operator' === token.type) {
		if ('date_offset_from_today' === nextToken?.type) {
			const { offsetFromToday, calendarUnit } = nextToken as DateOffsetFromTodayToken
			const date = [{ type: 'date', name: 'today', value: 'today' }]
			const operator = token as DateOperatorToken
			const dateOffset = offsetFromToday
			const dateExpressionToken: DateExpressionToken = {
				type: 'date_expression',
				date,
				operator,
				dateOffset,
				calendarUnit
			}
			return [2, parseToken(dateExpressionToken)]
		}
	}
	if ('date_offset_from_today' === token?.type) {
		const { offsetFromToday, calendarUnit } = token as DateOffsetFromTodayToken
		const date = [{ type: 'date', name: 'today', value: 'today' }]
		const operator = { type: 'arithmetic_operator', name: 'plus', value: '+' }
		const dateOffset = offsetFromToday
		const dateExpressionToken = {
			type: 'date_expression',
			date,
			operator,
			dateOffset,
			calendarUnit
		}
		return [1, parseToken(dateExpressionToken)]
	}
	return [0, undefined]
}

const tokenizers: Tokenizer[] = [
	tokenizeDateOffset,
	tokenizeMacroCalls,
	tokenizeKeywordFromQName,
	tokenizeLessThanGreaterThanAsNotEqual,
	consumeToken
]

export function tokenizeRules(tokens: Token[] = []) {
	let current = 0
	let consumed = false
	const processors: ProcessNode[] = []
	while (current < tokens.length) {
		consumed = false
		tokenizers.forEach((tokenizer) => {
			if (consumed) {
				return
			}
			const [consumedTokenCount, tokenized] = tokenizer(tokens, current)
			current = current + consumedTokenCount
			if (consumedTokenCount > 0) {
				if (tokenized != null) {
					consumed = true
					if (Array.isArray(tokenized)) {
						tokenized.forEach((processor) => processors.push(processor))
					} else {
						processors.push(tokenized)
					}
				}
			}
		})
		if (!consumed) {
			throw new TypeError(`unable to parse token at position ${current}`)
		}
	}
	return processors
}

export function parseTokens(tokens: Token[] = []): ProcessNode {
	const processors = tokenizeRules(tokens)
	return function processNode(node: any, context: any): string {
		return processors.map((processNode) => processNode(node, context)).join('')
	}
}

export function parseToken(token?: Token): ProcessNode {
	if (token == null) {
		return () => ''
	}
	switch (token.type) {
		case 'date':
			return dateResolverFactory(token as DateToken)
		case 'path':
		case 'qname':
			return qualifiedNameResolverFactory(token as QualifiedNameToken)
		case 'number':
		case 'boolean':
		case 'path_expression':
		case 'arithmetic_operator':
		case 'comparison_operator':
		case 'assignment_operator':
		case 'logical_operator':
		case 'negate_operator':
			return tokenValueResolver(token)
		case 'string':
			return stringResolver(token as StringToken)
		case 'regular_expression':
			return regularExpressionResolver(token as RegularExpressionToken)
		case 'date_expression':
			return dateExpressionResolver(token as DateExpressionToken)
		case 'ternary_if':
			return ternaryIfResolver(token as TernaryIfToken)
		case 'function_call':
			return functionCalResolverFactory(token as FunctionCallToken)
		case 'macro_calls':
			return macroCallsResolverFactory(token as MacroCallsToken)
		case 'macro_call':
			return macroCallResolverFactory(token as MacroCallToken)
		case 'parenthetical_expression':
			return parentheticalExpressionResolver(token as ParentheticalExpressionToken)
		case 'path_to_function_call':
			return pathToFunctionCallResolver(token as PathToFunctionCallToken)
		default:
			throw new TypeError(`uknown token "${token?.type}"`)
	}
}

export function qualifiedNameResolverFactory(token: QualifiedNameToken): ProcessNode {
	const { value } = token
	const resolver: Resolver<QualifiedNameToken> | undefined = qualifiedNameResolvers[value]
	if (resolver === undefined) {
		return tokenValueResolver(token)
	}
	return resolver(token)
}

export function booleanQualifiedNameResolver(token: QualifiedNameToken): ProcessNode {
	return function (): string {
		return String(token.value)
	}
}

export function todayQualifiedNameResolver(token: QualifiedNameToken): ProcessNode {
	const today: DateToken = { type: 'date', name: 'today', value: 'today' }
	return dateResolverFactory(today)
}

export function nowQualifiedNameResolver(token: QualifiedNameToken): ProcessNode {
	const now: DateToken = { type: 'date', name: 'now', value: 'now' }
	return dateResolverFactory(now)
}

export function parentheticalExpressionResolver(token: ParentheticalExpressionToken): ProcessNode {
	const { value } = token
	return function (node: any, context: any) {
		const resolver = parse(value)
		return `(${resolver(node, context)})`
	}
}

export function iifMacroCallResolver(token: MacroCallToken): ProcessNode {
	const { value } = token
	return parse(value)
}

export function minMacroCallResolver(token: MacroCallToken): ProcessNode {
	const inputValue = valueResolverFactory<number>(0)
	return function (node: any, context: any): string {
		const tokenValue: ProcessNode = parse(token.value)
		return `(${inputValue(node, context)} >= ${tokenValue(node, context)})`
	}
}

export function maxMacroCallResolver(token: MacroCallToken): ProcessNode {
	const inputValue = valueResolverFactory<number>(0)
	return function (node: any, context: any): string {
		const tokenValue: ProcessNode = parse(token.value)
		return `(${inputValue(node, context)} <= ${tokenValue(node, context)})`
	}
}

export function sameAsMacroCallResolver(token: MacroCallToken): ProcessNode {
	const inputValue = valueResolverFactory<any>()
	const processNode: ProcessNode = parse(token.value)
	return function processSameAs(node: any, context: any): string {
		const path = processNode(node, context)
		const lookupValue = processExpression(context, path, node)
		return `('${inputValue(node, context)}' == '${lookupValue}')`
	}
}

export function macroCallsResolverFactory(token: MacroCallsToken): ProcessNode {
	const { value } = token
	const useValue = Array.isArray(value) ? value : [value]
	const calls: ProcessNode[] = useValue.map(parse)
	return function resolveMacroCalls(node: any, context: any): string {
		return calls.map((macro) => macro(node, context)).join(' ')
	}
}

export function macroCallResolverFactory(token: MacroCallToken): ProcessNode {
	const { name } = token
	const resolver: Resolver<MacroCallToken> | undefined = macroCallResolvers[name]
	if (resolver === undefined) {
		const names = Object.keys(macroCallResolvers)
		throw new TypeError(`invalid macro_call name expect ${names.join(', ')} but got "${name}"`)
	}
	return resolver(token)
}

export function functionCalResolverFactory(token: FunctionCallToken): ProcessNode {
	const { name } = token
	const resolver: Resolver<FunctionCallToken> | undefined = functionCalResolvers[name]
	if (resolver === undefined) {
		return defaultFunctionCalResolver(token)
	}
	return resolver(token)
}

export function pathToFunctionCallResolver(token: PathToFunctionCallToken): ProcessNode {
	const { path: pathToken, functionCall: functionCallToken } = token
	const path = pathToken.value
	const functionCall = functionCallToken.value
	return function resolvePathToFunctionCall(node: any, context: any): string {
		const predicate = `${path}.${functionCall}`
		return predicate
	}
}

export function valueFunctionCallResolver(token: FunctionCallToken): ProcessNode {
	const inputValueResolver = valueResolverFactory<any>()
	return function (node: any, context: any): string {
		const value = inputValueResolver(node, context)
		if (typeof value === 'string') {
			return JSON.stringify(value)
		}
		return String(value)
	}
}

export function lengthFunctionCallResolver(token: FunctionCallToken): ProcessNode {
	const inputValueResolver = valueResolverFactory<any>()
	return function (node: any, context: any): string {
		const value = inputValueResolver(node, context)
		if (typeof value === 'string') {
			return JSON.stringify(value.length)
		}
		if (Array.isArray(value)) {
			return JSON.stringify(value.length)
		}
		return String(0)
	}
}

export function dateFunctionCallResolver(token: FunctionCallToken): ProcessNode {
	const { parameters = [] } = token
	if (parameters.length > 0) {
		throw new TypeError(
			`date() does not take any parameters but found ${JSON.stringify({
				parameters
			})}`
		)
	}
	return function processDateFunctionCall(node: any, context: any): string {
		// date values are inclusive to the end of the calendar day
		const date = resolveDateTimeFromContext(node, context).endOf('day')
		if (date.isValid) {
			debug.addInfo(node, `${date} is valid`)
		} else {
			debug.addInfo(node, `date is ${date.invalidExplanation} ${date.invalidExplanation}`)
		}
		return `${date.toMillis()}`
	}
}

export function sumFunctionCallResolver(token: FunctionCallToken): ProcessNode {
	const { parameters = [] } = token
	if (parameters.length > 0) {
		throw new TypeError(
			`sum() does not take any parameters but found ${JSON.stringify({
				parameters
			})}`
		)
	}
	function getSum(node: any, context: any): number {
		return context?.$?.sum(node.name)
	}
	return function processSum(node: any, context: any): string {
		const sum: number = getSum(node, context)
		debug.addInfo(node, `sum() is ${sum}`)
		return `${sum}`
	}
}

export function taintedFunctionCallResolver(token: FunctionCallToken): ProcessNode {
	const { parameters = [] } = token
	if (parameters.length > 0) {
		throw new TypeError(
			`tainted() does not take any parameters but found ${JSON.stringify({
				parameters
			})}`
		)
	}
	const valueResolver = valueResolverFactory<any>()
	return function processTaintedFunctionCall(node: any, context: any): string {
		const inputValue = valueResolver(node, context)

		debug.addInfo(node, { isTainted: false })

		if (inputValue == null) {
			// undefined means skip this validation
			// the required value will handle this
			return String(true)
		}

		if (isEmptyValue(inputValue)) {
			return String(true)
		}

		if (typeof inputValue === 'string') {
			// script tags
			if (/<\s*script\s*>/i.test(inputValue)) {
				debug.addInfo(node, { isTainted: true })
				return String(false)
			}
			// html entities
			if (/&[^\b\s]+;/i.test(inputValue)) {
				debug.addInfo(node, { isTainted: true })
				return String(false)
			}
		}
		if (Array.isArray(node.choices)) {
			const choices: { value: any; label: any }[] = node.choices
			const validValues = choices.map(({ label, value }) => (isEmptyValue(value) ? label : value))
			const useInputValue = Array.isArray(inputValue) ? inputValue : [inputValue]
			const badOptions = useInputValue
				.filter((value) => value != null)
				.map((value) => String(value))
				.filter((findValue) => {
					return !validValues.includes(String(findValue))
				})
			if (badOptions.length > 0) {
				debug.addInfo(node, { isTainted: true })
				debug.addInfo(node, `expected values ${validValues.join(', ')}`)
				return String(false)
			}
		} else if (node.choice && isPlainObject(node.choice)) {
			const { value } = node.choice
			const validValues = [value]
			const hasBadOption = inputValue != null && !validValues.includes(String(inputValue))
			if (hasBadOption) {
				debug.addInfo(node, { isTainted: true })
				debug.addInfo(node, `expected values ${validValues.join(', ')}`)
				return String(false)
			}
		}
		return String(true)
	}
}

export function defaultFunctionCalResolver(token: FunctionCallToken): ProcessNode {
	const { name, parameters = [] } = token
	return function resolveFunctionCall(node: any, context: any): string {
		return `${name}(${parameters.map((t: Token) => parse(t)(node, context)).join(', ')})`
	}
}

export function dateExpressionResolver(token: DateExpressionToken): ProcessNode {
	const { date, operator, dateOffset, calendarUnit }: DateExpressionToken = token
	const dateValue = parse(date)
	return function (node: any, context: any) {
		function getDateTimeValue() {
			const base: DateTime = DateTime.fromMillis(Number(dateValue(node, context)))
			if (operator.name === 'plus') {
				return base.plus({ [calendarUnit.name]: dateOffset.value })
			}
			if (operator.name === 'minus') {
				return base.minus({ [calendarUnit.name]: dateOffset.value })
			}
			throw new TypeError(
				`invalid date expression operator expected 'plus' or 'minus' but got ${JSON.stringify(
					token
				)}`
			)
		}
		const tokenDateValue = getDateTimeValue().endOf('day')
		debug.addInfo(node, `token date value is ${tokenDateValue}`)
		return String(tokenDateValue.toMillis())
	}
}

export function stringResolver(token: StringToken): ProcessNode {
	const { value, quote } = token
	return function () {
		if (quote == null) {
			return `"${value}"`
		}
		return String(value)
	}
}

export function dateResolverFactory(token: DateToken): ProcessNode {
	return function () {
		const date: DateTime = parseDateTimeFromToken(token)
		return String(date.toMillis())
	}
}

export function tokenValueResolver(token: Token): ProcessNode {
	const value: string = token.value
	return function () {
		return String(value)
	}
}

export function regularExpressionResolver(token: RegularExpressionToken): ProcessNode {
	const { value: expression, ignore = false } = token
	const flags = ignore ? 'i' : ''
	const valueResolver = valueResolverFactory<string>('')
	return function (node: any, context: any) {
		const inputValue = valueResolver(node, context)
		const re = new RegExp(expression, flags)
		debug.addInfo(node, `/${re.source}/.test("${inputValue}")`)
		return String(re.test(String(inputValue)))
	}
}

function ternaryIfResolver(token: TernaryIfToken): ProcessNode {
	const expression: ProcessNode = parse(token.expression)
	const truthy: ProcessNode = parse(token.truthy)
	const falsy: ProcessNode = parse(token.falsy)
	return function resolveTernaryIf(node: any, context: any): string {
		const expressionPredicate = expression(node, context)
		const truthyPredicate = truthy(node, context)
		const falsyPredicate = falsy(node, context)
		if (expressionPredicate === '') {
			throw new TypeError(
				`invalid conditional ternary operator expect expression but did not get one: ${JSON.stringify(
					node
				)}`
			)
		}
		debug.addInfo(node, `(${expressionPredicate}) ? (${truthyPredicate}) : (${falsyPredicate})`)
		return `(${expressionPredicate}) ? (${truthyPredicate}) : (${falsyPredicate})`
	}
}

export function parseDateTimeFromToken(token: DateToken): DateTime {
	const { name, value } = token
	if (name === 'now') {
		return DateTime.local()
	}
	if (name === 'today') {
		return DateTime.local().endOf('day')
	}
	if (name === 'milliseconds') {
		return DateTime.fromMillis(Number(value))
	}
	throw new TypeError(
		`invalid date expression expect token name to be 'today', 'now' or 'milliseconds' but got token: ${JSON.stringify(
			token
		)}`
	)
}

export function resolveDateTimeFromContext(node: Resolvable, context: any): DateTime {
	const value = getValue<any>(context, node)
	if (value == null) {
		return DateTime.invalid('unparsable', `input value is "${value}"`)
	}
	if (isEmptyValue(value)) {
		return DateTime.invalid('unparsable', `input value is "${JSON.stringify(value)}"`)
	}
	return dateTimeFromAnyInput(value, node.parseFormat)
}

export function warnIfDateTimeInvalid(dateTime: DateTime): DateTime {
	if (dateTime.invalidReason) {
		console.warn(dateTime.invalidReason, dateTime.invalidExplanation)
	}
	return dateTime
}

export function dateTimeFromFormat(input: string, format: string): DateTime {
	if (/^YYYY-MM-DD/.test(format)) {
		return DateTime.fromISO(input, { zone: 'utc' })
	}
	return warnIfDateTimeInvalid(DateTime.fromFormat(String(input).trim(), format))
}

export type Format = string | string[] | ((value: any) => DateTime)

export function dateTimeFromAnyInput(value: any, format: Format = 'M/d/yy'): DateTime {
	if (typeof format === 'function') {
		return format(value)
	}
	if (value == null) {
		return DateTime.invalid('unparsable', `input value is "${value}"`)
	}
	if (typeof value === 'string' && value.trim() === '') {
		return DateTime.invalid('unparsable', `input value is "${value}"`)
	}
	if (value?.isLuxonDateTime) {
		return value
	}
	if (value instanceof Date) {
		return warnIfDateTimeInvalid(DateTime.fromJSDate(value))
	}
	if (Number.isInteger(Number(value))) {
		if (!isNaN(parseInt(value, 10))) {
			return warnIfDateTimeInvalid(DateTime.fromMillis(Number(value)))
		}
	}
	if (isPlainObject(value as DateObject)) {
		return warnIfDateTimeInvalid(DateTime.fromObject(value))
	}
	if (typeof value === 'string') {
		return dateTimeFromStringInput(value, format)
	}
	return DateTime.invalid('unparsable', `input value is "${JSON.stringify(value)}"`)
}

export function dateTimeFromStringInput(value: string, format?: Format): DateTime {
	if (typeof format === 'function') {
		return format(value)
	}
	if (value == null) {
		return DateTime.invalid('unparsable', `input value is "${value}"`)
	}
	if (value.trim() === '') {
		return DateTime.invalid('unparsable', `input value is "${value}"`)
	}
	if (Number.isInteger(Number(value))) {
		if (!isNaN(parseInt(value, 10))) {
			return warnIfDateTimeInvalid(DateTime.fromMillis(Number(value)))
		}
	}

	if (isEmptyValue(format)) {
		// deduce the format from the value itself
		// default format parsing for US locale (month day year)
		if (/\d\d?\/\d\d?\/\d\d\d/.test(value)) {
			return dateTimeFromFormat(value, 'M/d/yy')
		}
		if (/\d\d?-\d\d?-\d\d\d/.test(value)) {
			return dateTimeFromFormat(value, 'M-d-yy')
		}
		if (/\d\d? \d\d? \d\d\d/.test(value)) {
			return dateTimeFromFormat(value, 'M d yy')
		}
		// default format parsing for any locale year month day
		if (/\d\d\d\/\d\d?\/\d\d?/.test(value)) {
			return dateTimeFromFormat(value, 'yy/M/d')
		}
		if (/\d\d\d \d\d? \d\d?/.test(value)) {
			return dateTimeFromFormat(value, 'yy M d')
		}
	} else {
		return dateTimeFromFormat(value, String(format))
	}
	return warnIfDateTimeInvalid(DateTime.fromISO(value, { zone: 'utc' }))
}
